import { WalletActivityFilter } from "~/models/__constants__";
import { Chain } from "~/types";
import { cn } from "~/utils/cn";
import { CurrencyIcon } from "./CurrencyIcon";
import { bigNumberConverter2 } from "~/utils/convertDecimals";

const getTextAndColor = (type: WalletActivityFilter) => {
  switch (type) {
    case WalletActivityFilter.BURN:
      return {
        color: "#ef4444",
        colorBg: "bg-[#ef4444]/20",
        colorText: "text-[#ef4444]",
        text: "Burn",
      };
    case WalletActivityFilter.BUY:
      return {
        color: "#15994d",
        colorBg: "bg-[#15994d]/20",
        colorText: "text-[#15994d]",
        text: "Buy",
      };
    case WalletActivityFilter.MINT:
      return {
        color: "#2c91c8",
        colorBg: "bg-[#2c91c8]/20",
        colorText: "text-[#2c91c8]",
        text: "Mint",
      };
    case WalletActivityFilter.SELL:
      return {
        color: "#f84672",
        colorBg: "bg-[#f84672]/20",
        colorText: "text-[#f84672]",
        text: "Sell",
      };
    case WalletActivityFilter.TRANSFER_FROM:
      return {
        color: "#27272b",
        colorBg: "bg-[#27272b]/20",
        colorText: "text-[#fdfdfd]",
        text: "Transfer",
      };
    case WalletActivityFilter.TRANSFER_TO:
      return {
        color: "#27272b",
        colorBg: "bg-[#27272b]/20",
        colorText: "text-[#fdfdfd]",
        text: "Receive",
      };
    case WalletActivityFilter.UNKNOWN:
      return {
        color: "#27272b",
        colorBg: "bg-[#27272b]/20",
        colorText: "text-[#fdfdfd]",
        text: "Unkown",
      };
  }
};

export const WalletActivityBadge = ({
  type,
  quantity,
  price,
}: {
  type: WalletActivityFilter;
  quantity: number;
  price?: {
    value: string;
    currency: string;
  };
}) => {
  const q = quantity > 1 ? quantity + " " : "";
  const { text, colorBg, colorText, color } = getTextAndColor(type);

  if (price)
    return (
      <div className="justify-start items-center gap-[3px] flex">
        <div
          className={cn(
            "px-2 py-1.5 rounded-tl-md rounded-tr-[3px] rounded-bl-md rounded-br-[3px] justify-center items-center gap-2 flex",
            colorBg
          )}
        >
          <div className={cn("text-xs font-semibold font-['Inter'] leading-3", colorText)}>
            {q} {text}
          </div>
        </div>

        <div
          className={cn(
            "px-2 py-1.5  rounded-tl-[3px] rounded-tr-md rounded-bl-[3px] rounded-br-md justify-center items-center gap-1 flex",
            colorBg
          )}
        >
          <div className={cn("text-xs font-medium font-['Inter'] leading-3", colorText)}>
            {bigNumberConverter2(price.value)}
          </div>
          <CurrencyIcon
            color={color}
            chain={price.currency === "BTC" ? Chain.Bitcoin : Chain.Ethereum}
          />
        </div>
      </div>
    );
  else
    return (
      <div className="flex items-center gap-[1px] text-gray-200 text-xs font-semibold">
        <div
          className={cn(
            colorBg,
            colorText,
            "px-2 rounded-md min-w-[26px] h-6 flex items-center justify-center"
          )}
        >
          {text}
        </div>
      </div>
    );
};
